<template>
  <div class="subject">
    <!-- 导航栏 -->
    <div class="nav">
      <div class="nav_t">
        <img src="@/assets/images/logo.png" alt="" />
        <span>供销为农服务平台</span>
      </div>
      <el-menu
        :default-active="navtype"
        class="el-menu-vertical-demo"
        @close="handleClose"
        background-color="#42959A"
        text-color="#fff"
        active-text-color="#fff"
      >
        <el-menu-item @click="goscreen" index="screen">
          <img
            style="width: 0.28rem; margin-right: 0.08rem"
            src="@/assets/images/information.png"
            alt=""
          />
          <span style="color: #fff">智慧大屏</span>
        </el-menu-item>
        <el-menu-item @click="handleOpen" index="InformationCenter">
          <img
            style="width: 0.28rem; margin-right: 0.08rem"
            src="@/assets/images/information.png"
            alt=""
          />
          <span style="color: #fff">信息中台</span>
        </el-menu-item>
        <el-menu-item @click="handleOpen" index="supplyAndMarketing">
          <img
            style="width: 0.28rem; margin-right: 0.08rem"
            src="@/assets/images/serve.png"
            alt=""
          />
          <span style="color: #fff">供销管理</span>
        </el-menu-item>

        <el-sub-menu index="1">
          <template #title>
            <img
              style="width: 0.28rem; margin-right: 0.08rem"
              src="@/assets/images/loan.png"
              alt=""
            />
            <span style="color: #fff">贷款服务</span>
          </template>
          <el-menu-item @click="handleOpen" index="Process">
            <span style="color: #fff">贷款初审(肉牛)</span>
          </el-menu-item>
          <el-menu-item @click="handleOpen" index="Repayment">
            <span style="color: #fff">还款管理(肉牛)</span>
          </el-menu-item>
          <el-menu-item @click="handleOpen" index="GrainProcess">
            <span style="color: #fff">贷款初审(粮油)</span>
          </el-menu-item>
           <el-menu-item @click="handleOpen" index="RepaymentGrain">
            <span style="color: #fff">还款管理(粮油)</span>
          </el-menu-item>
          <el-menu-item @click="handleOpen" index="LoanSummaryTable">
            <span style="color: #fff">贷款总表</span>
          </el-menu-item>
          <!-- <el-menu-item @click="handleOpen" index="GrainRepayment">
          <span style="color: #fff">还款管理(粮油)</span>
            </el-menu-item> -->
        </el-sub-menu>

        <el-menu-item
          @click="handleOpen"
          index="GeneralManagement"
          v-if="role == 'admin'"
        >
          <img
            style="width: 0.28rem; margin-right: 0.08rem"
            src="@/assets/images/serve.png"
            alt=""
          />
          <span style="color: #fff">系统设置</span>
        </el-menu-item>
      </el-menu>
    </div>
    <!-- 内容 -->
    <div class="content">
      <div class="content_t">
        <img src="@/assets/images/tx.png" alt="" />
        <span class="f01">{{ lname }}</span>
        <span @click="onBack">退出</span>
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
export default {
  name: "HomeView",
  components: {},
  setup() {
    let navtype = "InformationCenter";
    const router = useRouter();
    // 角色分类  admin管理员 server_center 服务中心 business_entity 经营主体
    let role = "";
    role = JSON.parse(sessionStorage.getItem("user")).role;
    let lname = JSON.parse(sessionStorage.getItem("user")).name;

    const handleOpen = (key) => {
      if (key.index == "InformationCenter") {
        router.push({ name: "InformationCenter" });
      } else if (key.index == "supplyAndMarketing") {
        router.push({ path: "/supplyAndMarketing" });
      } else if (key.index == "GeneralManagement") {
        router.push({ path: "/GeneralManagement" });
      } else if (key.index == "Process") {
        router.push({ path: "/Process" });
      } else if (key.index == "Repayment") {
        router.push({ path: "/Repayment" });
      } else if (key.index == "GrainProcess") {
        router.push({ path: "/GrainProcess" });
      } else if (key.index == "GrainRepayment") {
        router.push({ path: "/GrainRepayment" });
      } else if (key.index == 'LoanSummaryTable'){
        router.push({ path: '/LoanSummaryTable'})
      }else if (key.index == 'RepaymentGrain'){
        router.push({ path: '/RepaymentGrain'})
      }
    };
    const handleClose = (key, keyPath) => {
      console.log(key, keyPath);
    };

    let urli = () => {
      navtype = window.location.href.split("/")[3];
      if (navtype == "home") {
        navtype = "InformationCenter";
      } else if (navtype == "HeadOffice") {
        navtype = "ServiceCentre";
      } else if (navtype == "administratorsBE") {
        navtype = "BusinessEntity";
      }
    };
    urli();

    let onBack = () => {
      router.push({ path: "/" });
      sessionStorage.removeItem("user");
    };

    // 数据大屏
    let goscreen = () => {
      router.push({ path: "/screen" });
    };
    return {
      handleOpen,
      handleClose,
      navtype,
      role,
      lname,
      onBack,
      goscreen,
    };
  },
};
</script>
<style lang="less" scoped>
.subject {
  display: flex;
  height: 100%;
  // 导航栏
  .nav {
    background-color: #42959a;
    .nav_t {
      display: flex;
      padding: 0.22rem 0.31rem 0.2rem 0.15rem;
      img {
        width: 0.48rem;
        height: 0.48rem;
        border-radius: 0.08rem;
      }
      span {
        line-height: 0.48rem;
        font-size: 0.14rem;
        color: #fff;
        font-weight: 600;
        padding-left: 0.05rem;
      }
    }
  }
  // 登录信息
  .content {
    flex: 1;
    background-color: #f9f9f9;
    .content_t {
      background-color: #fff;
      display: flex;
      justify-content: right;
      padding: 0.24rem 0.48rem;
      img {
        width: 0.32rem;
      }
      span {
        font-size: 0.18rem;
        color: #666;
        line-height: 0.32rem;
      }
      .f01 {
        padding: 0 0.48rem 0 0.06rem;
      }
    }
  }
}
.el-menu {
  border-right: 0.01rem solid #42959a;
}
.el-menu-item.is-active {
  background-color: #214a4d;
}
</style>
