import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [{
        path: '/',
        name: 'login',
        component: () =>
            import ("../views/LoginView.vue"),
    },
    {
        path: '/home',
        name: 'home',
        component: HomeView,
        // meta: { requireAuth: true },
        children: [{ //信息中台
                path: '/home',
                name: 'InformationCenter',
                component: () =>
                    import ("../views/InformationCenter/InformationCenter.vue"),
            },
            {
                path: '/supplyAndMarketing',
                name: 'supplyAndMarketing',
                component: () =>
                    import ("../views/supplyAndMarketing/supplyAndMarketing.vue"),
            },
            {
                path: '/GeneralManagement',
                name: 'GeneralManagement',
                component: () =>
                    import ("../views/GeneralManagement/GeneralManagement.vue"),
            },
            { //贷款初审
                path: '/Process',
                name: 'Process',
                component: () =>
                    import ("../views/Loan/Process.vue"),
            },
            { //贷款还款
                path: '/Repayment',
                name: 'Repayment',
                component: () =>
                    import ("../views/Loan/Repayment.vue"),
            },
            { //贷款还款
                path: '/RepaymentGrain',
                name: 'RepaymentGrain',
                component: () =>
                    import ("../views/Loan/RepaymentGrain.vue"),
            },
            { //贷款初审 粮油
                path: '/GrainProcess',
                name: 'GrainProcess',
                component: () =>
                    import ("../views/Loan/GrainProcess.vue"),
            },
            { //贷款初审 粮油
                path: '/GrainRepayment',
                name: 'GrainRepayment',
                component: () =>
                    import ("../views/Loan/GrainRepayment.vue"),
            },
            { // 贷款总表
                path: '/LoanSummaryTable',
                name: 'LoanSummaryTable',
                component: () =>
                    import ("../views/Loan/LoanSummaryTable.vue")
            }
        ]
    }, {
        path: '/screen',
        name: 'screen',
        component: () =>
            import ("../views/screen.vue"),
    }

]

console.log(process.env)
const router = createRouter({
    history: createWebHistory(process.env.VUE_APP_PUBLIC_PATH),
    routes
})

export default router