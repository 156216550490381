import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "../src/assets/style/reset.css"
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
router.beforeEach((to, from, next) => {
    if (to.meta.requireAuth) { // 判断该路由是否需要登录权限
        if (sessionStorage.getItem('user')) { //判断本地是否存在access_token
            next();
        } else {
            if (to.path === '/') {
                next();
            } else {
                alert('请先进行登录！')
                next({
                    path: '/'
                })
            }
        }
    } else {
        next();
    }
    /*如果本地 存在 token 则 不允许直接跳转到 登录页面*/
    if (to.fullPath == "/login") {
        if (sessionStorage.getItem('user')) {
            alert('您已经登录了，如果想要登录其他账号，请先退出当前账号！')
            next({
                path: from.fullPath
            });
        } else {
            next();
        }
    }
});

createApp(App).use(store).use(router).use(ElementPlus).mount('#app')